import React from 'react';
import { Card} from 'react-bootstrap';


const ServiceCard = (props) => {
    return (
        <Card className="p-sm-3">
            <div className="card-image-container">
                <Card.Img variant="top" src={props.img} width="300px" height="300px" />
            </div>

            <h2><a href={props.link}>{props.title}</a></h2>
            <p>{props.copy}</p>
    </Card>
    )
};
export default ServiceCard;