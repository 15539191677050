import React, { Component } from "react";
import Slider from "react-slick";
import FordLogo from "../assets/car-logos/ford.webp"
import HyuandiLogo from "../assets/car-logos/hyuandi.webp"
import IsuzuLogo from "../assets/car-logos/isuzu.webp"
import MazdaLogo from "../assets/car-logos/mazda.webp";
import ToyotaLogo from "../assets/car-logos/toyota.webp";
import KiaLogo from "../assets/car-logos/kia.webp";
import MitsubishiLogo from "../assets/car-logos/mitsubishi.webp";
import SubaruLogo from "../assets/car-logos/subaru.webp";
import NissanLogo from "../assets/car-logos/nissan.webp";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 5000,
      autoplaySpeed: 5000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
          <img src={FordLogo} alt="ford-logo" width="200px" height="90"></img>
          </div>
          <div>
            <img src={HyuandiLogo} alt="ford-logo" width="200px" height="100"></img>
          </div>
          <div>
           <img src={IsuzuLogo} alt="ford-logo" width="200px" height="100"></img>
          </div>
          <div>
            <img src={MazdaLogo} alt="ford-logo" width="200px" height="100"></img>
          </div>
          <div>
            <img src={ToyotaLogo} alt="ford-logo" width="200px" height="150"></img>
          </div>
          <div>
            <img src={KiaLogo} alt="ford-logo" width="200px" height="100"></img>
          </div>
          <div>
            <img src={MitsubishiLogo} alt="ford-logo"width="200px" height="100"></img>
          </div>
          <div>
            <img src={SubaruLogo} alt="ford-logo" width="200px" height="100"></img>
          </div>
          <div>
            <img src={NissanLogo} alt="ford-logo" width="130px" height="100"></img>
          </div>
        </Slider>
      </div>
    );
  }
}