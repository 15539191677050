import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import Layout from "../components/layout";
import ServiceCard from '../components/service-card'
import Stars from "../components/stars";
import AutoPlay from "../components/autoplay.js";
import InfoBanner from "../components/info-banner";
import InfoImage from "../assets/banner-red.jpg";
import { SEO } from "../components/seo"

import PDR from "../assets/before-after-white.webp"
import OldMechanical from "../assets/old-mechanical.jpeg"
import OldCar from "../assets/old-car.jpeg"
import Polishing from "../assets/polishing.jpeg"
const Index = () => {
    return (
        <Layout>
            <Container fluid className="pdrm__hero-wrapper p-0">
                <Container className="pdrm__hero p-0">
                    <Row>
                        <Col>
                            <div className="pdrm__hero-text-wrapper">
                            <Container className="pdrm__hero-text">
                            <Stars></Stars>
                            <h1 className="pdrm__hero-title">Paintless dent removal and <br></br> mechanical</h1>
                            <h4>Veteran family owned mobile repairs, servicing New England <br></br> and the North West Slopes &amp; Plains NSW </h4>
                                <Button href="/about" className="pdrm-button secondary">About</Button>  
                                <Button href="tel:0407348941" className="pdrm-button secondary">Get a quote</Button>  
                            </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            
            <InfoBanner white heading="About Patriot" copy="We are a Tamworth based mobile repair business which specialises in paintless dent removal and mechanical." copy2="Patriot specialises in paintless dent removal (PDR). PDR is a cost and time efficient solution for your next dent or ding." copy3="We also offer a range of mechanical services, such as log book servicing, li" pathName={InfoImage}></InfoBanner>

            <Container fluid className="pdrm__services--background">
                <Container fluid className="pdrm__services p-md-5 p-2">              
                        <Row className="g-0">
                            <h2 className="pdrm__services--title py-4 px-3 px-sm-5">OUR SERVICES</h2>
                            <Col sm={6} xl={3} md={6} className="d-flex justify-content-around p-0">
                            <ServiceCard img={PDR} title="Paintless Dent" copy={"Paintless dent repairs is a method of panel beating that requires a specific set of skills and tools to do correctly, but allows the vehicle to maintain original factory paint. Paintless dent repairs also saves time and is cheaper than traditional repairs."} link="/paint-repairs"></ServiceCard>
                            </Col>
                            <Col sm={6} xl={3} md={6} className="d-flex justify-content-around p-0">
                                <ServiceCard img={OldMechanical} title="Mechanical" copy="We offer an array of mechanical services including log book &amp; brake servicing, diagnostic code reading and clearing (engine lights, air bag lights etc), classic car servicing & maintenance, and vehicle unlocking." link="/mechanical"></ServiceCard>
                            </Col>
                            <Col sm={6} xl={3} md={6} className="d-flex justify-content-around p-0">
                                <ServiceCard img={OldCar} title="Classic cars" link="/classic-cars" copy=" We have a passion for working on classic vehicles, either mechanical or dent repairs. Where most dent guys won’t touch classic cars, we will always have a go at fixing them."></ServiceCard>
                            </Col>
                            <Col sm={6} xl={3} md={6} className="d-flex justify-content-around p-0">
                                <ServiceCard img={Polishing} copy="Spruce up your car exteriors with a highly effective car buff or polish. Correct minor paint imperfections without having to repaint." title="Buffing and polishing" link="/paint-repairs"></ServiceCard>
                            </Col>
                        </Row>
                    <div className="pdrm__services--background__white"></div>
                </Container>
            </Container>


            <Container fluid className="pdrm__models--background p-5">
                <Row className="g-0">
                    <h2 className="pdrm__models--title">VEHICLES WE REPAIR</h2>
                    <Container>
                        <AutoPlay></AutoPlay>
                    </Container>
                </Row>
            </Container>

        </Layout>
    )
};
export default Index;

export const Head = () => (
    <SEO />
  )